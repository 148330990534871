*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background-color: black;
    color: #FFF;
}
img {
    height: auto;
    max-width: 100%;
}
.topbar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.brandlogo {
    flex: 0 0 10vw;
    max-width: 120px;
    min-width: 80px;
}
.payementarea {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payementbox {
    max-width: 600px;
    padding: 20px;
    border: 1px solid aqua;
    margin-inline: 10px;
    border-radius: 12px;
}
.MuiFormControl-root {
    .MuiFormLabel-root {
        color: aqua;
    
    }
    .MuiFormLabel-root.Mui-focused{
        color: aqua;
    }
    .MuiFormLabel-root.Mui-disabled{
        color: rgba(0, 255, 255, 0.5);
    }
    
}
 .MuiFormControl-root {
    .MuiInputBase-root {
        color: #01ffff;
    }
 }
.MuiInputBase-root {
    width: 100%;
    &:hover fieldset {
        border-color: #53ffffa6 !important;
    }
    fieldset {
        border-color: aqua;
    }
    .MuiSvgIcon-root {
        color:aqua;
    }
    
    
}
.MuiInputBase-root.Mui-focused {
    color: #FFF;
    fieldset {
        border-color: aqua !important;
    }
}
.MuiInputBase-root.Mui-disabled {
    fieldset {
        border-color: aqua !important;
    }
}

.MuiGrid-root {
    .submitaddess {
        margin: 25px 5px 0px;
            background-color: #01ffff;
    color: #001f1f;
    font-weight: 600;
    &:hover {
        background-color: #115c5f;
        color: #fff;
    }
    }
    .submitaddess.Mui-disabled {
        background-color: #01ffff8a;
        color: #001f1f;
    }
}
.deposit-box{
    strong{
        word-break: break-all;
    }
}
.maxbtn{
    padding: 8px 12px;
    background-color: aqua;
    color: #000;
    font-weight: 700;
    border-radius: 20px;
    cursor: pointer;
    border: transparent;
}
.currency-menubox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.currency-menubox .image-box {
    max-width: 35px;
    margin-right: 15px;
    max-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.currency-menubox span {
    font-size: 17px;
    font-weight: 500;
    color: aqua;
}
.currency-menubox .image-box img{
    width: 100%;
    height: auto;
}
.image-box {
    max-width: 25px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-box img{
    width: 100%;
    height: auto;
}
.flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.MuiPaper-root.MuiMenu-paper{
    background-color: rgb(0 34 34) !important;
}
